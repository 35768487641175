<template>
  <div class="vg_wrapper">
    <el-card>
      <!--      <div class="vd_search_group">-->
      <!--        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getUsersNow()">-->
      <!--          <el-row :gutter="24">-->
      <!--            <el-col :md="8">-->
      <!--              <el-form-item label="部门名称：">-->
      <!--                <el-select v-model="searchForm.dept_id" placeholder="请选择部门" clearable @visible-change="getDeptV1($event)" size="small">-->
      <!--                  <el-option v-for="item in deptGroupOpt" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"> </el-option>-->
      <!--                </el-select>-->
      <!--              </el-form-item>-->
      <!--            </el-col>-->
      <!--            <el-col :md="8">-->
      <!--              <el-form-item label="小组名称：">-->
      <!--                <el-select v-model="searchForm.dept_team_id" placeholder="请选择小组" clearable @visible-change="getDeptTeams" size="small">-->
      <!--                  <el-option-->
      <!--                    v-for="item in deptTeamsGroupOpt"-->
      <!--                    :key="item.dept_team_id"-->
      <!--                    :label="item.dept_team_name"-->
      <!--                    :value="item.dept_team_id"-->
      <!--                  >-->
      <!--                  </el-option>-->
      <!--                </el-select>-->
      <!--              </el-form-item>-->
      <!--            </el-col>-->
      <!--            <el-col :md="8">-->
      <!--              <el-form-item label="员工账号：">-->
      <!--                <el-input size="small" v-model.trim="searchForm.user_name" clearable placeholder="请填写账号"></el-input>-->
      <!--              </el-form-item>-->
      <!--            </el-col>-->
      <!--          </el-row>-->
      <!--          <el-row :gutter="24">-->
      <!--            <el-col :md="8">-->
      <!--              <el-form-item label="员工姓名：">-->
      <!--                <el-input size="small" v-model.trim="searchForm.stff_name" clearable placeholder="请填写姓名"></el-input>-->
      <!--              </el-form-item>-->
      <!--            </el-col>-->
      <!--            <el-col :md="8">-->
      <!--              <el-form-item label="账号状态：">-->
      <!--                <el-select v-model="searchForm.user_status" clearable size="small" placeholder="请选择账号状态">-->
      <!--                  <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>-->
      <!--                </el-select>-->
      <!--              </el-form-item>-->
      <!--            </el-col>-->
      <!--            &lt;!&ndash;         <el-col :md="4">&ndash;&gt;-->
      <!--            &lt;!&ndash;           <el-form-item>&ndash;&gt;-->
      <!--            &lt;!&ndash;             <el-button size="small" type="primary" icon="el-icon-search" @click="getUsersNow()">查询</el-button>&ndash;&gt;-->
      <!--            &lt;!&ndash;           </el-form-item>&ndash;&gt;-->
      <!--            &lt;!&ndash;         </el-col>&ndash;&gt;-->
      <!--          </el-row>-->
      <!--          <el-row>-->
      <!--            <el-col :md="12">-->
      <!--              <el-form-item label="创建时间：">-->
      <!--                <div class="block">-->
      <!--                  <el-date-picker-->
      <!--                    size="small"-->
      <!--                    v-model="searchForm.timeValue"-->
      <!--                    type="datetimerange"-->
      <!--                    range-separator="至"-->
      <!--                    start-placeholder="开始日期"-->
      <!--                    end-placeholder="结束日期"-->
      <!--                  >-->
      <!--                  </el-date-picker>-->
      <!--                </div>-->
      <!--              </el-form-item>-->
      <!--              <el-form-item>-->
      <!--                <el-button size="small" type="primary" icon="el-icon-search" @click="getUsersNow()" class="vg_ml_16">查询</el-button>-->
      <!--              </el-form-item>-->
      <!--            </el-col>-->
      <!--          </el-row>-->
      <!--        </el-form>-->
      <!--      </div>-->
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" @click="delRow()" :disabled="dleDis"><i class="el-icon-delete"></i> 注销</el-button>
        <el-button type="success" size="small" @click="exportList" :disabled="!btn.delete"
          ><i class="el-icon-top-right"></i> 导出</el-button
        >
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
          ><i class="el-icon-refresh-right"></i> 刷新</el-button
        >
      </div>
      <!--      <el-row>-->
      <!--        <el-col :md="24">-->
      <!--          <el-table-->
      <!--            v-loading="loadFlag"-->
      <!--            ref="multiTable"-->
      <!--            :data="tableData"-->
      <!--            @selection-change="handleSelectionChange"-->
      <!--            @row-dblclick="dbClickJp"-->
      <!--            border-->
      <!--            class="vg_cursor"-->
      <!--          >-->
      <!--            <el-table-column type="selection" width="48" align="center" />-->
      <!--            <el-table-column label="账号" prop="user_name" :show-overflow-tooltip="true" />-->
      <!--            <el-table-column label="姓名" prop="stff_name" :show-overflow-tooltip="true" />-->
      <!--            <el-table-column label="部门" prop="dept_name" :show-overflow-tooltip="true" />-->
      <!--            <el-table-column label="小组" prop="dept_team_name" :show-overflow-tooltip="true">-->
      <!--              <template slot-scope="scope">-->
      <!--                <span v-if="scope.row.dept_team_name">-->
      <!--                  {{ scope.row.dept_team_name }}-->
      <!--                </span>-->
      <!--                <span v-else class="vg_9f9a9a">暂无</span>-->
      <!--              </template>-->
      <!--            </el-table-column>-->
      <!--            <el-table-column label="账号状态" prop="user_status" align="center">-->
      <!--              <template slot-scope="scope">-->
      <!--                <el-tag type="info" v-if="scope.row.user_status == 0">已注销</el-tag>-->
      <!--                <el-tag v-if="scope.row.user_status == 1">未使用</el-tag>-->
      <!--                <el-tag type="success" v-if="scope.row.user_status == 2">使用中</el-tag>-->
      <!--              </template>-->
      <!--            </el-table-column>-->
      <!--            <el-table-column label="同步系统" prop="user_status" align="center">-->
      <!--              <template slot-scope="scope">-->
      <!--                <div class="vd_sync">-->
      <!--                  <div v-if="scope.row.dyj_user_id" class="vd_font">打样间</div>-->
      <!--                  <div v-else class="vd_font">暂无</div>-->
      <!--                </div>-->
      <!--              </template>-->
      <!--            </el-table-column>-->
      <!--            <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" />-->
      <!--            <el-table-column label="注销时间" prop="delete_time" :formatter="formatDateDel" />-->
      <!--          </el-table>-->
      <!--        </el-col>-->
      <!--      </el-row>-->
      <!--      <el-row>-->
      <!--        <el-col :md="24">-->
      <!--          <pubPagination ref="pubPagination" :totalPage="totalPage" @changePageSearch="changePageSearch"></pubPagination>-->
      <!--        </el-col>-->
      <!--      </el-row>-->
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`user_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @selection-change="handleSelectionChange"
        @row-dblclick="dbClickJp"
        @getTableData="getDequsNow"
      >
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { deptAPI } from '@api/modules/department';
import { userAPI } from '@api/modules/user';
import pubPagination from '@/components/common/pubPagination';
import { getDate, getDateNoTime } from '@assets/js/dateUtils';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { debounce } from 'lodash';
export default {
  name: 'UserList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      tableProperties: [
        { prop: 'selection', type: 'selection', labelWidth: '48px' },
        { prop: 'user_name', label: '账号', itemType: 'input', widthAuto: true },
        { prop: 'stff_name', label: '姓名', itemType: 'input', widthAuto: true },
        { prop: 'dept_name', label: '部门', widthAuto: true, itemType: 'select', options: [] },
        { prop: 'dept_team_name', label: '小组', widthAuto: true, input: false, itemType: 'input' },
        {
          prop: 'user_status',
          label: '账号状态',
          widthAuto: true,
          itemType: 'select',
          options: [
            { value: '0', label: '已注销' },
            { value: '1', label: '未使用' },
            { value: '2', label: '使用中' }
          ],
          formatter: val => {
            if (val === 0) {
              return '已注销';
            } else if (val === 1) {
              return '未使用';
            } else if (val === 2) {
              return '使用中';
            }
          }
        },
        {
          prop: 'dyj_user_id',
          label: '同步系统',
          itemType: 'input',
          input: false,
          widthAuto: true,
          formatter: val => {
            if (val) {
              return '打样间';
            } else {
              return '暂无';
            }
          }
        },
        {
          prop: 'create_time',
          label: '创建时间',
          itemType: 'date',
          widthAuto: true,
          formatter: val => getDateNoTime(val, true)
        },
        {
          prop: 'delete_time',
          label: '注销时间',
          itemType: 'date',
          widthAuto: true,
          input: false,
          formatter: val => {
            if (!this.helper.toStringDate(val)) {
              return '暂未注销';
            } else {
              return this.helper.toStringDate(val);
            }
          }
        }
      ],
      //options
      statusOptions: [
        {
          value: '0',
          label: '已注销'
        },
        {
          value: '1',
          label: '未使用'
        },
        {
          value: '2',
          label: '使用中'
        }
      ],
      searchForm: {
        // user_name: '',
        // stff_name: '',
        // dept_id: null,
        // dept_team_id: null,
        // user_status: null,
        // timeValue: {
        //   startTime: null,
        //   endTime: null
        // }
      },
      loadFlag: true,
      cpttGroupOpt: [],
      deptGroupOpt: [],
      deptTeamsGroupOpt: [],
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {}
    };
  },
  created() {
    // this.initData();
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/user_add' || from.path === '/user_edit') {
        this.initData();
      }
    }
  },
  computed: {
    dleDis() {
      let res = this.multiSelection.some(item => {
        return item.user_status == 0;
      });
      return res;
    }
  },
  methods: {
    initData() {
      this.getUsersList();
      this.getDeptV1(true);
      this.getDeptTeams(true);
    },
    // 查询方法
    getDequsNow: debounce(function () {
      this.loadFlag = true;
      this.currentPage = 1;
      this.getUsersList();
    }, 1000),
    getUsersList() {
      // const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      // const searchBody = {
      //   user_name: this.searchForm.user_name,
      //   stff_name: this.searchForm.stff_name,
      //   dept_id: this.searchForm.dept_id,
      //   dept_team_id: this.searchForm.dept_team_id,
      //   page_no: this.currentPage,
      //   start_time: timeNewVal.startTime,
      //   end_time: timeNewVal.endTime,
      //   user_status: this.searchForm.user_status
      // };
      let searchForm = this.$refs.multiTable.searchForm;
      get(userAPI.getUsers, searchForm).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          setTimeout(() => {
            this.loadFlag = false;
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    // 获取deptId
    getDeptV1(flag) {
      if (flag === true && this.deptGroupOpt.length === 0) {
        get(deptAPI.getAllDeptsV1).then(res => {
          if (res.data.code === 0) {
            this.deptGroupOpt = res.data.data;
            this.tableProperties[3].options = Array.from(this.deptGroupOpt, ({ dept_name, dept_id }) => {
              return { value: dept_id, label: dept_name };
            });
          }
        });
      }
    },
    // 获取deptTeamId
    getDeptTeams(flag) {
      if (flag === true && this.deptTeamsGroupOpt.length === 0) {
        get(deptAPI.getAllDeptTeams).then(res => {
          if (res.data.code === 0) {
            this.deptTeamsGroupOpt = res.data.data;
            this.tableProperties[4].options = Array.from(this.deptTeamsGroupOpt, ({ dept_team_name, dept_team_id }) => {
              return { value: dept_team_id, label: dept_team_name };
            });
          }
        });
      }
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    formatDateDel(row) {
      if (!this.helper.toStringDate(row.delete_time)) {
        return '暂未注销';
      } else {
        return this.helper.toStringDate(row.delete_time);
      }
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 查询
    getUsersNow() {
      // this.loadFlag = true;
      // this.$refs.pubPagination.currentPage = 1;
      // this.currentPage = 1;
      // this.getUsersList();
    }, // 刷新
    buttonRefresh() {
      // this.searchForm = {
      //   user_name: '',
      //   stff_name: '',
      //   dept_id: null,
      //   dept_team_id: null,
      //   user_status: null,
      //   timeValue: {
      //     startTime: null,
      //     endTime: null
      //   }
      // };
      this.$refs.multiTable.resetFields();
      this.loadFlag = true;
      // this.currentPage = 1;
      this.getUsersList();
      // this.$refs.pubPagination.currentPage = 1;
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getUsersList();
    },
    // 双击编辑
    dbClickJp(row) {
      this.jump('/user_edit', { perm_id: this.$route.query.perm_id, form_id: row.user_id });
    },
    // 新增
    addRow() {
      this.jump('/user_add', { perm_id: this.$route.query.perm_id });
    },
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.user_id);
      });
      // 判断是否选择有用户
      if (this.multiSelection.length <= 0) {
        this.$message.warning('请至少选择一条');
        return;
      }
      this.$confirm('是否注销该数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          post(userAPI.deleteUserByIds, { user_id_list: ids })
            .then(res => {
              if (res.data.code === 0) {
                let mg = res.data.msg;
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    // this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.initData();
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消注销'
          });
          this.$refs.multiTable.clearSelection();
        });
      // this.currentPage = 1
      // this.getUsersList()
    },
    //导出
    exportList() {
      get(userAPI.export_user)
        .then(res => {
          if (res.data.code === 0) {
            this.excelUrl = this.helper.megPath(res.data.data.url);
            this.excelName = '账号信息列表' + '.' + 'xlsx';
            this.helper.downloadItemC(this.excelUrl, this.excelName);
            this.$message({ message: '导出成功', type: 'success' });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_sync {
  display: flex;
  justify-content: space-around;
}
.vd_font {
  width: 60px;
  height: 20px;
  background-color: #54b0b6;
  color: #ffffff;
  border-radius: 5px;
  text-align: center;
  line-height: 20px;
}
</style>
